import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ApprovalTasksDialogComponent} from '../../dialogs/approval-tasks-dialog/approval-tasks-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {OnboardingCoordinatorTaskDialogComponent} from '../../dialogs/onboarding-coordinator-task-dialog/onboarding-coordinator-task-dialog.component';

@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})

export class TasksComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  waiting = true;
  columns: any[] = [];
  tableItems: any[] = [];
  filteredTableItems: any[] = [];
  currentObject = { id: 'all', name: 'Common.All' };
  isMobile: boolean;
  filters = [
    { id: 'all', name: 'Common.All' },
    { id: 'forAcceptance', name: 'DashboardWidgetTasks.ForAcceptance' },
    { id: 'forRealization', name: 'DashboardWidgetTasks.ForRealization' },
  ];

  public request: RequestInterface = {
    sort: {
      field: 'id',
      direction: 'DESC'
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50,
      totalElementsCount: 0,
      totalPages: 0
    },
    waitingForData: false
  };

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _router: Router,
              private _translate: TranslateService,
              private deviceService: DeviceDetectorService,
              private _dialog: MatDialog) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.createTable();
    if(!this.preview){
      this.getData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  getData(filter = ''): void {
    this.request.waitingForData = true;
    if(this.preview){
      this.request.pagination.totalElementsCount = 5;
      this.request.pagination.totalPages = 1;
      this.request.waitingForData = false;
    }else{
      this._api.Dashboard.getTasksData().subscribe((res) => {
        this.tableItems = res;
        this.tableItems.sort(function(a,b){
          // @ts-ignore
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        this.filteredTableItems = this.tableItems;
        this.waiting = false;
        this.prepareWidgetData()
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

  const tableItems = [];
  tableItems.push(
      {
        name: 'Spotkanie z przełożonym',
        type: 'preboarding',
        users : [
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName2'),
            avatarPath: null
          },
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName1'),
            avatarPath: null
          },
        ]
      },
      {
        name: 'Odbiór kluczy',
        type: 'onboarding',
        users : [
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName4'),
            avatarPath: null
          },
        ]
      },
      {
        name: 'Przekazanie sprzętu',
        type: 'gamification',
        users : [
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName5'),
            avatarPath: null
          },
        ]
      },
      {
        name: 'Spotkanie z przełożonym',
        type: 'onboarding',
        users : [
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName4'),
            avatarPath: null
          },
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName5'),
            avatarPath: null
          },
        ]
      },
      {
        name: 'Przekazanie sprzętu',
        type: 'gamification',
        users : [
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName4'),
            avatarPath: null
          },
          {
            userFullName: this._translate.instant('DashboardWidgetExample.UserName5'),
            avatarPath: null
          },
        ]
      },
  );
  this.filteredTableItems = tableItems;
  }

  createTable() {
    this.columns = [
      {
        index: 'name',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '200px',
        },
        class: 'ft-auto',
      },
      {
        index: 'users',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'AVATARS',
        css: {
          'padding-left': '10px',
          'min-width': '80px',
        },
        render: () => {
          return '';
        }
      },
      {
        index: 'type',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
        },
        render: (v) => {
          if(v.type === 'onboarding'){
            return '<span class="onboarding">' + this._translate.instant('Common.Onboarding') + '</span>';
          }else if(v.type === 'preboarding'){
            return '<span class="preboarding">' + this._translate.instant('Common.Preboarding') + '</span>';
          }else{
            return '<span class="gamification">' + this._translate.instant('Common.Gamification') + '</span>';
          }
        }
      },
      {
        index: 'action',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'MENU',
        items: [
          {
            name: 'Gamification.AcceptTheTask',
            icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-show-task-to-approve "></i>',
            visible: (v) => {
              return true;
            },
            click: (v) => {
              this.openApprovalDialog(v);
            }
          }
        ],
        css: {
          'padding-left': '10px',
          'min-width': '50px',
        },
      },
    ]
  }

  openApprovalDialog(data: any) {
    if(data.category === 'forAcceptance') {
      if (data.type === 'onboarding' || data.type === 'preboarding') {
        this._dialog.open(ApprovalTasksDialogComponent, {
          disableClose: true,
          width: '70vw',
          height: '80vh',
          panelClass: 'filter-popup',
          data: {
            onboarding: {
              id: data.onboardingId,
              name: data.onboardingName
            },
            userName: data.userName,
            showButtons: true,
            fromComponent: 'team',
            userId: data.userId,
            params: `?sortBy=${this.request.sort.field}&order=${this.request.sort.direction}`,
            isPreboarding: data.type === 'preboarding'
          }
        }).afterClosed().subscribe(() => {
          this.request.pagination.pageNumber = 1;
          this.getData('');
        })
      } else {
        this._dialog.open(ApprovalTasksDialogComponent, {
          disableClose: true,
          width: '70vw',
          height: '80vh',
          panelClass: 'filter-popup',
          data: {
            gamification: {
              id: data.gamificationId,
              name: data.gamificationName
            },
            userName: data.userName,
            showButtons: true,
            fromComponent: 'team',
            userId: data.userId,
            params: `?sortBy=${this.request.sort.field}&order=${this.request.sort.direction}`,
            isPreboarding: data.type === 'preboarding'
          }
        }).afterClosed().subscribe(() => {
          this.request.pagination.pageNumber = 1;
          this.getData('');
        })
      }
    }else{
      this._dialog.open(OnboardingCoordinatorTaskDialogComponent, {
        disableClose: true,
        width: '70vw',
        height: '80vh',
        panelClass: 'filter-popup',
        data: {
          onboarding: {
            id: data.onboardingId,
            name: data.onboardingName
          },
          userName: data.userName,
          showButtons: true,
          fromComponent: 'team',
          userId: data.userId,
          params: `?sortBy=username&order=${this.request.sort.direction}`,
          isPreboarding: data.type === 'preboarding'
        }
      }).afterClosed().subscribe(() => {
        this.request.pagination.pageNumber = 1;
        this.getData('');
      })
    }
  }

  setFilter(filter){
    this.currentObject = filter;
    if(filter.id === 'all'){
      this.filteredTableItems = this.tableItems;
    }else{
      this.filteredTableItems = this.tableItems.filter(obj => {
        return obj.category === filter.id
      })
    }
  }

  goTo(data){
    if(!this.preview) {
      if(data.category === 'forRealization'){
        if (data.type === 'onboarding') {
          this._router.navigateByUrl('/app/team/onboarding/' + data.id + '/coordinators-tasks');
        } else {
          this._router.navigateByUrl('/app/team/preboarding/' + data.id + '/coordinators-tasks');
        }
      }else{
        if (data.type === 'onboarding') {
          this._router.navigateByUrl('/app/team/onboarding/' + data.id + '/users');
        } else if (data.type === 'preboarding') {
          this._router.navigateByUrl('/app/team/preboarding/' + data.id + '/users');
        } else {
          this._router.navigateByUrl('/app/team/gamification/' + data.id + '/users');
        }
      }
    }
  }

}