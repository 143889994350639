<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-favorite' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardCreator.Favorite' | translate}}</div>
    <svg class="background-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="white" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" d="M29.9999 41.624L28.127 39.9448C25.9527 37.9858 24.1551 36.2958 22.7343 34.875C21.3135 33.4542 20.1833 32.1786 19.3437 31.0484C18.5041 29.9182 17.9175 28.8795 17.5838 27.9323C17.2501 26.9851 17.0833 26.0163 17.0833 25.026C17.0833 23.0024 17.7614 21.3125 19.1176 19.9562C20.4739 18.6 22.1638 17.9219 24.1874 17.9219C25.3069 17.9219 26.3725 18.1587 27.3843 18.6323C28.3961 19.1059 29.268 19.7733 29.9999 20.6344C30.7319 19.7733 31.6037 19.1059 32.6155 18.6323C33.6273 18.1587 34.693 17.9219 35.8124 17.9219C37.836 17.9219 39.526 18.6 40.8822 19.9562C42.2385 21.3125 42.9166 23.0024 42.9166 25.026C42.9166 26.0163 42.7497 26.9851 42.4161 27.9323C42.0824 28.8795 41.4958 29.9182 40.6562 31.0484C39.8166 32.1786 38.6864 33.4542 37.2655 34.875C35.8447 36.2958 34.0471 37.9858 31.8728 39.9448L29.9999 41.624Z" fill="white"/>
    </svg>

    <div class="widget-container__content">
        <div *ngIf="widget.pathCount > 0" class="widget-container__content--item-count">{{ 'DashboardWidgetFavorite.Paths' | translate}}
            <span [style.background]="hexToRGB('#' + widget.elementsColor,0.15)">{{ widget.pathCount }}</span>
        </div>
        <div *ngIf="widget.trainingCount > 0" class="widget-container__content--item-count">{{ 'DashboardWidgetFavorite.Trainings' | translate}}
            <span [style.background]="hexToRGB('#' + widget.elementsColor,0.15)">{{ widget.trainingCount }}</span>
        </div>
        <div *ngIf="widget.articleCount > 0" class="widget-container__content--item-count">{{ 'DashboardWidgetFavorite.Articles' | translate}}
            <span [style.background]="hexToRGB('#' + widget.elementsColor,0.15)">{{ widget.articleCount }}</span>
        </div>
        <div *ngIf="widget.pathCount === 0 && widget.trainingCount === 0 && widget.articleCount === 0" class="widget-container__content--description">{{ 'DashboardWidgetFavorite.EmptyStatusText' | translate}}</div>
    </div>
</div>
