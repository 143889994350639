<div class="widget-shortcut-add-edit">
    <h1 mat-dialog-title tabIndex="0">
        {{ (!data.addshortcut ? 'DashboardWidgetUserShortcut.EditUrl' : 'DashboardWidgetUserShortcut.AddUrl') | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="box--head">
            <img src="/assets/icons/url-icon.svg" alt="letter-a">
            {{'DashboardWidgetUserShortcut.Url' | translate}}
        </div>
        <div class="box--content">
            <form [formGroup]="form">
                <mat-form-field class="full-width">
                    <input matInput formControlName="url" placeholder="{{'DashboardWidgetUserShortcut.UrlAddress' | translate}} *">
                    <mat-error *ngIf="form.get('url').hasError('required')">
                        {{ 'Common.FiledCanNotBeEmpty' | translate }}
                    </mat-error>
                    <mat-error *ngIf="urlError !== ''">{{ urlError }}</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input #name matInput formControlName="name" placeholder="{{'DashboardWidgetUserShortcut.UrlName' | translate}} *" maxlength="100">
                    <mat-error *ngIf="form.get('name').hasError('required')">
                        {{ 'Common.FiledCanNotBeEmpty' | translate }}
                    </mat-error>
                    <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
                </mat-form-field>
            </form>

            <span class="info"><img src="/assets/icons/info.svg" alt="format-selection">{{'DashboardWidgetUserShortcut.ItWillOpenInSameWindow' | translate}}</span>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-flat-button (click)="dialogRef.close()" color="primary" class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-flat-button (click)="save()" color="primary" class="dolineo-button blue-button">{{ (!data.addshortcut ? 'Common.Update' : 'Common.Add') | translate}}</button>
    </div>
</div>
