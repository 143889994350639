<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-training-needs' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(300deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 10%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardCreator.TrainingNeeds' | translate}}</div>
    <svg class="background-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="white" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" fill-rule="evenodd" clip-rule="evenodd" d="M18.375 44.9596C18.9583 45.543 19.671 45.8346 20.5129 45.8346H39.4871C40.329 45.8346 41.0417 45.543 41.625 44.9596C42.2083 44.3763 42.5 43.6637 42.5 42.8217V22.918L33.75 14.168H20.5129C19.671 14.168 18.9583 14.4596 18.375 15.043C17.7917 15.6263 17.5 16.3389 17.5 17.1809V42.8217C17.5 43.6637 17.7917 44.3763 18.375 44.9596ZM40 24.168H32.5V16.668L40 24.168ZM23.125 33.5711L28.4487 38.8949L37.2308 30.0711L35.4904 28.2891L28.4071 35.3724L24.8654 31.8307L23.125 33.5711Z" fill="white"/>
    </svg>
    <div class="widget-container__content">
        <div class="widget-container__content--description">{{ 'DashboardWidgetTrainingNeeds.Content' | translate}} →</div>
    </div>
</div>
