import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public selfSurvey(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string, excludedIds?: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        let url =`${this.basePath}/admin/survey?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;

        if (excludedIds?.length > 0) {
            excludedIds.forEach(excludedId => {
                url += `&eid[]=${excludedId}`;
            });
        }

        return this.http.get<any>(url, { headers });
    }

    public getSurvey(id: number | string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/survey/${id}`, { headers });
    }

    public surveyCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/survey-name-validate`, body, { headers });
    }

    public myCreatedSurveys(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/survey?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public dolineoSurvey(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/dolineo-survey?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public dolineoSurveysXls(params: string): string {
        return `/admin/dolineo-survey/xls?${params}`;
    }

    public templateSurveysXls(body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/template/xls/surveys`, body, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public surveyCreate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/admin/survey`, body, { headers });
    }

    public surveyUpdate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/survey`, body, { headers });
    }

    public downloadSurveyAdmin(params: string): string {
        return `/admin/survey/xls?${params}`;
    }

    public surveyExportQuestions(body: any): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/admin/survey/export`, body, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public surveyImportQuestions(file: any, translations: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('translations', JSON.stringify(translations));

        return this.http.post<any>(`${this.basePath}/admin/survey/import`, formParams, { reportProgress: true, observe: 'events' });
    }

    public toggleActiveDolineoSurvey(surveyId: number, status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-survey/${surveyId}/activity`, { isActive: status }, { headers });
    }

    public automaticPublicationDolineoSurvey(status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-survey/automatic-publication`, { isAutomatic: status }, { headers });
    }

    public surveyUsers(surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/survey/${surveyId}/user-checklist`, { headers });
    }

    public surveyUsersAssigned(surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/survey/${surveyId}/assigned`, { headers });
    }

    public assignSurvey(surveyId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/survey/${surveyId}/assign`, body, { headers });
    }

    public getSurveyLibraryDetails(surveyId: number| string, isPreview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        const preview = isPreview? '?preview=1': '';
        return this.http.get<any>(`${this.basePath}/survey/${surveyId}${preview}`, { headers });
    }

    public getSurveyUserDetails(surveyId: number| string, userId: number| string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/survey/${surveyId}`, {headers});
    }

    public startSurvey(idUser: number, surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/survey/${surveyId}/attempt/start`, {}, {headers});
    }

    public finishSurvey(idUser: number, surveyId: number, surveyAttemptId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/survey/${surveyId}/attempt/${surveyAttemptId}/finish`, {}, {headers});
    }

    public updateQuestion(idUser: number, surveyId: number, surveyAttemptId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/survey/${surveyId}/attempt/${surveyAttemptId}/update`, {...body},{headers});
    }

    public deleteQuestion(idUser: number, surveyId: number, surveyAttemptId: number, questionId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/${idUser}/survey/${surveyId}/attempt/${surveyAttemptId}/question/${questionId}`, {headers});
    }

    public getUsersAnswers(surveyId: number| string, userId: number| string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/survey/${surveyId}/stats`, {headers});
    }

    public getSurveysOrganization(companyId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/surveys?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadSurveyAnswersOrganization(companyId: number, id: number): string {
        return `/company/${companyId}/organization/surveys-answers/${id}`;
    }

    public surveysOrganizationXLS(companyId: number, params: string): string {
        return `/company/${companyId}/organization/surveys/xls?${params}`;
    }

    public getSurveysOrganizationResults(companyId: number, surveyId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/surveys?surveyId=${surveyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSurveysOrganizationGeneralReport(companyId: number, surveyId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/surveys-general-report?surveyId=${surveyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSurveysOrganizationResultsXLS(companyId: number, surveyId: number, params: string): string {
        return `/company/${companyId}/organization/surveys/xls?surveyId=${surveyId}&${params}`;
    }

    public getSurveysOrganizationGeneralReportXLS(companyId: number, surveyId: number, params: string): string {
        return `/company/${companyId}/organization/surveys-general-report/xls?surveyId=${surveyId}&${params}`;
    }

    public getSurveyNameOrganization(companyId: number, surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
            return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/survey/${surveyId}`, { headers });
    }

    public getSurveysOrganizationStats(companyId: number, surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/survey/${surveyId}/stats`, { headers });
    }

    public getSurveysTeam(userId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/surveys?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSurveysTeamGeneralReport(userId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/surveys-general-report?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadSurveyAnswersTeam(userId: number, id: number): string {
        return `/user/${userId}/team/surveys-answers/${id}`;
    }

    public surveysTeamXLS(userId: number, params: string): string {
        return `/user/${userId}/team/surveys/xls?${params}`;
    }

    public surveysTeamGeneralReportXLS(userId: number, params: string): string {
        return `/user/${userId}/team/surveys-general-report/xls?${params}`;
    }

    public getSurveysTeamResults(userId: number, surveyId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/surveys?surveyId=${surveyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSurveysTeamResultsXLS(userId: number, surveyId: number, params: string): string {
        return `/user/${userId}/team/surveys/xls?surveyId=${surveyId}&${params}`;
    }

    public removeSurveyAttempt(attemptId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/survey/attempt/${attemptId}`, { headers });
    }

    public getSurveyNameTeam(userId: number, surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/survey/${surveyId}`, { headers });
    }

    public getSurveysTeamStats(userId: number, surveyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/survey/${surveyId}/stats`, { headers });
    }

    public getCompanyProductGroupSurveys(companyId: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${companyId}/product-group/survey?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getCompanyProductGroupSurveysXLS(companyId: any, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${companyId}/product-group/survey/xls?sortBy=${sortBy}&order=${order}&${params}`
    }

    public getCompanySurveys(companyId: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${companyId}/surveys?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getCompanySurveysXLS(companyId: number, params: string): string {
        return `/superadmin/company/${companyId}/surveys/xls?${params}`;
    }

    public getSurveyStatOrgPerUser(companyId: any, surveyId: any, userAnswerId: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/surveys/${surveyId}/answers/${userAnswerId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getSurveyStatTeamPerUser(userId: any, surveyId: any, userAnswerId: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/surveys/${surveyId}/answers/${userAnswerId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public downloadOrgStats(companyId: number, surveyId: number): string {
        return `/company/${companyId}/organization/survey/${surveyId}/stats`;
    }

    public downloadTeamStats(userId: number, surveyId: number): string {
        return `/user/${userId}/team/survey/${surveyId}/stats`;
    }
}
