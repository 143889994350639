<div class="widget-container"
     *ngIf="items"
     [ngClass]="('widget-container-new-employees' + ' widget-container-size-' + (setSize ? widget.sizeClass : '') + (preview ? ' no-overflow-table' : ''))"
     [style.background]="'#' + widget.backgroundColor"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.NewEmployees' | translate}}</div>

    <div class="widget-container__content">
        <div class="widget-container__content--new-employees">
            <app-data-grid *ngIf="tableItems.length > 0"
                            [columns]="columns"
                           [items]="tableItems"
                           [request]="request"
                           (loadData)="getData($event)"
                           [noTabs]="false"
                           [selection]="false"
                           [hideHead]="true">
            </app-data-grid>
        </div>
    </div>
</div>

