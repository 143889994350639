import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';
import {validateInternalUrl} from '../../helpers';

@Component({
    selector: 'dashboard-widget-shortcut-add-edit-dialog',
    templateUrl: './dashboard-widget-shortcut-add-edit-dialog.component.html',
    styleUrls: ['./dashboard-widget-shortcut-add-edit-dialog.component.scss']
})
export class DashboardWidgetShortcutAddEditDialogComponent implements OnInit, OnDestroy {

    form: FormGroup;
    subscriptions: Subscription = new Subscription();
    urlError = '';

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<DashboardWidgetShortcutAddEditDialogComponent>,
                protected api: ApiService,
                private _translate: TranslateService,
                private _toastrService: ToastrService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.form = this.initForm();
        if (!this.data.addshortcut) {
            this.form.patchValue(this.data);
        }
        this.valueChanges();
    }

    initForm(): FormGroup {
        return this.fb.group({
            url: [this.data?.url ? this.data.url : '', {
                validators: [Validators.required, noWhitespaceValidator]
            }],
            name: [this.data?.name ? this.data.name : '', {
                validators: [Validators.required, noWhitespaceValidator]
            }]
        });
    }

    valueChanges() {
        this.subscriptions.add(this.form.get('url').valueChanges.subscribe((val: string) => {
            if (!validateInternalUrl(val)) {
                this.form.get('url').setErrors({ 'invalid': true });
                this.urlError = this._translate.instant('TrainingCreator.UrlAdressError')
            }else{
                this.urlError = '';
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    save() {
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }
        const url = this.form.get('url').value.trim();
        const name = this.form.get('name').value.trim();

        if (!this.data.addshortcut) {
            this.subscriptions.add(this.api.Dashboard.updateCompanyWidgetshortcutUserUrl(this.data.widgetId, { name: name, url: url }).subscribe((res) => {
                this.dialogRef.close({
                    'url': url,
                    'name': name,
                });
            }, () => {
                this._toastrService.error(this._translate.instant('Err.ErrRetry'));
            }));
        } else {
            this.subscriptions.add(this.api.Dashboard.updateCompanyWidgetshortcutUserUrl(this.data.widgetId, { name: name, url: url }).subscribe((res) => {
                this.dialogRef.close({
                    'url': url,
                    'name': name,
                });
            }, () => {
                this._toastrService.error(this._translate.instant('Err.ErrRetry'));
            }));
        }
    }
}