import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})

export class UrlComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any = {
    name: null,
    sizeClass: null,
    backgroundColor: null,
    elementsColor: null,
    description: null,
  };
  whiteBackground: boolean = false;
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.prepareWidgetData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        name: null !== this.items.name && this.items.name !== undefined ? JSON.parse(this.items.name)[this._helper.getUser().interfaceLanguageCode] : '',
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
        description: null !== this.items.description && this.items.description !== undefined ? JSON.parse(this.items.description)[this._helper.getUser().interfaceLanguageCode] : '',
        url: null !== this.items.url && this.items.url !== undefined ? this.items.url : '',
        urlDisplay: null !== this.items.urlDisplay && this.items.urlDisplay !== undefined ? this.items.urlDisplay : '',
      };
      this.whiteBackground = this.widget.backgroundColor.toLowerCase() === 'ffffff';
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  goTo(){
    if(!this.preview) {
      if (this.items.urlDisplay === '0') {
        window.location.href = this.items.url
      } else {
        window.open(this.items.url, '_blank').focus();
      }
    }
  }

}