<div class="widget-container"

     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-user-shortcut' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container--box"
         (click)="goTo(url)"
         [style.cursor]="url ? 'pointer' : 'auto'"
    >
        <div class="widget-container--box__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetUserShortcut.MyShortcut' | translate}}</div>

        <svg class="background-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#40CED9" fill-opacity="0.2"/>
            <path [style.fill]="'#' + widget.elementsColor" fill-rule="evenodd" clip-rule="evenodd" d="M17.623 17.6345V46.3906L30.123 41.0868L42.623 46.3906V17.6345C42.623 16.7208 42.3081 15.9518 41.6782 15.3273C41.0482 14.7029 40.2724 14.3906 39.3508 14.3906H20.8953C19.9737 14.3906 19.1979 14.7029 18.5679 15.3273C17.938 15.9518 17.623 16.7208 17.623 17.6345ZM23.7505 31.0091C24.5841 31.8428 25.5861 32.2596 26.7568 32.2596H28.9324V30.2326H26.7568C26.1436 30.2326 25.6186 30.0141 25.1819 29.5772C24.7453 29.1402 24.527 28.6153 24.527 28.0023C24.527 27.3893 24.7453 26.8646 25.1819 26.428C25.6186 25.9914 26.1436 25.7731 26.7568 25.7731H28.9865V23.7461H26.7568C25.5766 23.7461 24.5721 24.165 23.7432 25.0028C22.9144 25.8407 22.5 26.8407 22.5 28.0029C22.5 29.1735 22.9168 30.1756 23.7505 31.0091ZM33.1757 26.9893H26.8243V29.0164H33.1757V26.9893ZM31.0811 30.2326V32.2596H33.2432C34.4139 32.2596 35.4159 31.8429 36.2495 31.0096C37.0832 30.1763 37.5 29.1742 37.5 28.0034C37.5 26.8149 37.0832 25.8083 36.2495 24.9834C35.4159 24.1585 34.4139 23.7461 33.2432 23.7461H31.0135V25.7731H33.2432C33.8564 25.7731 34.3814 25.9916 34.8181 26.4285C35.2547 26.8655 35.473 27.3904 35.473 28.0034C35.473 28.6164 35.2545 29.1411 34.8176 29.5777C34.3806 30.0143 33.8559 30.2326 33.2432 30.2326H31.0811Z" fill="#40CED9"/>
        </svg>

        <div class="widget-container--box__content">
            <button class="widget-container--box__content--button"
                    *ngIf="!url"
                    [style.background]="'#' + widget.elementsColor"
                    [style.color]="'#' + widget.backgroundColor"
                    (click)="addUrl()"
            >{{ 'DashboardWidgetUserShortcut.AddUrl' | translate}}</button>
            <span *ngIf="!url" class="widget-container--box__content--text">{{ 'DashboardWidgetUserShortcut.ForQuickAccess' | translate}}</span>
            <span class="widget-container--box__content--href" [style.color]="'#' + widget.elementsColor" *ngIf="url">{{ urlName }}</span>
        </div>
    </div>

    <i class="dolineo-icon dolineo-icon-v-dots"
       [matMenuTriggerFor]="edit"
       *ngIf="!preview"></i>

    <mat-menu #edit="matMenu">
        <button mat-menu-item (click)="addUrl(widget)">
            {{'Common.Edit' | translate}}
        </button>
    </mat-menu>
    
</div>
