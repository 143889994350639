<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-feedback' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
        {{ 'DashboardCreator.EmployeeFeedback' | translate}}
        <span class="widget-container__name--count">{{ widget.data.length }}</span>
    </div>

    <div class="widget-container__table">
        <div class="widget-container__table__feedbacks" *ngFor="let data of widget.data; let i = index;">
            <div class="widget-container__table__feedbacks--questionnaire-name">{{ i+1 }}{{ '. ' + data.questionnaireName }}</div>
            <div class="widget-container__table__feedbacks--questionnaire-date">{{ data.publicationStartDate | date : 'dd.MM.YYYY' }} - {{ data.publicationEndDate | date : 'dd.MM.YYYY' }}</div>
            <div class="widget-container__table__feedbacks__content">
                <div class="feedback-progress" *ngIf="data.invitations > 0">
                    <ng-container *ngIf="data.invitations > 0">
                        <div class="feedback-progress__info" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{'DashboardWidgetEmployeeFeedback.FeedbackProvided'| translate}}:</div>
                        <div class="feedback-progress__progress">
                            <div class="feedback-progress__progress__bar" [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor, 0.5) : ''">
                                <div class="progress-done" [style.background]="!whiteBackground ? '#' + widget.elementsColor : ''"
                                     [style.width]="((data.assessments/data.invitations) * 100) + '%'"></div>
                            </div>
                            <div class="feedback-progress__progress__number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                <span>{{data.assessments}}</span>
                                <span> / {{data.invitations}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="feedback-progress-space" *ngIf="data.participants > 0 || preview"></div>
                <div class="feedback-progress">
                    <ng-container *ngIf="data.participants > 0 || preview">
                        <div class="feedback-progress__info" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{'DashboardWidgetEmployeeFeedback.SelfAssessmentCompleted'| translate}}:</div>
                        <div class="feedback-progress__progress">
                            <div class="feedback-progress__progress__bar" [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor, 0.5) : ''">
                                <div class="progress-done" [style.background]="!whiteBackground ? '#' + widget.elementsColor : ''"
                                     [style.width]="((data.selfAssessments/data.participants) * 100) + '%'"></div>
                            </div>
                            <div class="feedback-progress__progress__number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                <span>{{data.selfAssessments}}</span>
                                <span> / {{data.participants}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
        
                <div class="widget-container__table__feedbacks__content--description" *ngIf="data.invitations > 0">
                    <span class="widget-container__table__feedbacks__content--description--label" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetEmployeeFeedback.ForFeedbackWaiting' | translate}}:</span>
                    <span class="widget-container__table__feedbacks__content--description--user-avatar"
                          *ngFor="let user of data.usersWaitingForFeedback; let index = index;"
                          matTooltip="{{ user.userFullName }}"
                          matTooltipPosition="above"
                          matTooltipClass='above'
                          [ngStyle]="data.usersWaitingForFeedback.length > 5 && index > 3 ? {'display' : 'none'} : {}">
                        <ngx-avatar [src]="user.avatarUrl"
                                    [name]="user.userFullName"
                                    size="28">
                        </ngx-avatar>
                    </span>/
                    <span *ngIf="(data.invitations - data.assessments) > 5" class="widget-container__table__feedbacks__content--description--empty-avatar">
                        <span>{{ '+' + (preview ? 4 : (data.invitations - data.assessments - 4)) }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="widget-container__table__feedbacks__content" style="height: 290px;" *ngIf="widget.data.length === 0">
            <div class="widget-container__table__feedbacks__content--empty-status">
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetEmployeeFeedback.IsEmptyText1' | translate}}</div>
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetEmployeeFeedback.IsEmptyText2' | translate}}</div>
                <img style="margin-right: 10px;" src="/assets/images/dashboard/clock.svg" alt="filter" class="mr-3">
            </div>
        </div>
    </div>
</div>
