<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-knowledge-base' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
        {{ 'DashboardCreator.KnowledgeBase' | translate}}
        <div class="widget-container__name--news"
             [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''"
             [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
        >{{ 'DashboardWidgetKnowledgeBase.News' | translate}}</div>
    </div>


    <div class="widget-container__content">
        <div class="widget-container__content--article" *ngFor="let article of widget.data.articles">
            <div class="widget-container__content--article--name"
            (click)="goTo(article.id)">{{ article.name }}</div>
            <div class="widget-container__content--article--categories">
                <div class="widget-container__content--article--categories--category"
                     [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.6) : ''"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     *ngFor="let category of article.categories">{{ category }}</div>
            </div>
        </div>
    </div>
</div>
