<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-employee-certificates' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardCreator.EmployeeCertificates' | translate}}</div>

    <svg class="background-icon"  width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#FF9900" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" d="M30.0001 29.8757L26.9447 32.1811C26.7963 32.283 26.6527 32.2854 26.5138 32.1882C26.3749 32.0909 26.3333 31.9636 26.3888 31.8061L27.5555 28.0561L24.4167 25.584C24.2779 25.482 24.2408 25.3547 24.3055 25.2019C24.3705 25.0491 24.4816 24.9727 24.6388 24.9727H28.4447L29.6667 21.1673C29.7223 21.0098 29.8334 20.9311 30.0001 20.9311C30.1667 20.9311 30.2779 21.0098 30.3334 21.1673L31.528 24.9727H35.3472C35.5047 24.9727 35.6181 25.0491 35.6876 25.2019C35.757 25.3547 35.7223 25.482 35.5834 25.584L32.4305 28.0561L33.5697 31.8061C33.6252 31.9636 33.5881 32.0909 33.4584 32.1882C33.3287 32.2854 33.1898 32.283 33.0417 32.1811L30.0001 29.8757ZM30.0001 45.0286L21.9584 47.7227C21.514 47.8802 21.0949 47.8155 20.7013 47.5286C20.308 47.2413 20.1113 46.8663 20.1113 46.4036V35.5977C18.9261 34.3386 18.0556 32.9405 17.5001 31.4036C16.9445 29.8663 16.6667 28.2876 16.6667 26.6673C16.6667 22.9082 17.9491 19.7461 20.5138 17.1811C23.0788 14.6163 26.2409 13.334 30.0001 13.334C33.7592 13.334 36.9213 14.6163 39.4863 17.1811C42.0511 19.7461 43.3334 22.9082 43.3334 26.6673C43.3334 28.2876 43.0556 29.8663 42.5001 31.4036C41.9445 32.9405 41.0741 34.3386 39.8888 35.5977V46.4036C39.8888 46.8663 39.6922 47.2413 39.2988 47.5286C38.9052 47.8155 38.4862 47.8802 38.0417 47.7227L30.0001 45.0286ZM30.0001 37.2227C32.9445 37.2227 35.4399 36.1997 37.4863 34.1536C39.5324 32.1072 40.5555 29.6118 40.5555 26.6673C40.5555 23.7229 39.5324 21.2275 37.4863 19.1811C35.4399 17.135 32.9445 16.1119 30.0001 16.1119C27.0556 16.1119 24.5602 17.135 22.5138 19.1811C20.4677 21.2275 19.4447 23.7229 19.4447 26.6673C19.4447 29.6118 20.4677 32.1072 22.5138 34.1536C24.5602 36.1997 27.0556 37.2227 30.0001 37.2227Z" fill="#FF9900"/>
    </svg>

    <div class="widget-container__content">
        <div class="widget-container__content--certificates--all" *ngIf="certificates.all > 0 && (widget.sizeClass === 'b' || isMobile)">
            <div class="widget-container__content--certificates--all--count"
                 [style.width]="widget.sizeClass === 'b' ? '200px' : 'width: 110px;'">{{ certificates.all }}</div>
        </div>
        <div class="widget-container__content--certificates--categories"
             *ngIf="certificates.all > 0 && !isMobile"
             [style.width]="widget.sizeClass === 'a' ? '100%' : 'width: calc(100% - 160px);'"
        >

            <div class="widget-container__content--certificates--categories--category" *ngIf="certificates.preboardingVisible || widget.sizeClass === 'b'">
                <div class="widget-container__content--certificates--categories--category--label"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Preboarding' | translate}}</div>
                <div class="widget-container__content--certificates--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ certificates.preboarding }}</div>
            </div>

            <div class="widget-container__content--certificates--categories--category" *ngIf="certificates.onboardingVisible || widget.sizeClass === 'b'">
                <div class="widget-container__content--certificates--categories--category--label"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Onboarding' | translate}}</div>
                <div class="widget-container__content--certificates--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ certificates.onboarding }}</div>
            </div>
            
            <div class="widget-container__content--certificates--categories--category" *ngIf="certificates.trainingVisible || widget.sizeClass === 'b'">
                <div class="widget-container__content--certificates--categories--category--label"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Trainings' | translate}}</div>
                <div class="widget-container__content--certificates--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ certificates.training }}</div>
            </div>

            <div class="widget-container__content--certificates--categories--category" *ngIf="certificates.gamificationVisible || widget.sizeClass === 'b'">
                <div class="widget-container__content--certificates--categories--category--label"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Gamifications' | translate}}</div>
                <div class="widget-container__content--certificates--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ certificates.gamification }}</div>
            </div>

            <div class="widget-container__content--certificates--categories--category" *ngIf="certificates.pathVisible || widget.sizeClass === 'b'">
                <div class="widget-container__content--certificates--categories--category--label"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Paths' | translate}}</div>
                <div class="widget-container__content--certificates--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ certificates.path }}</div>
            </div>

        </div>
        <div class="widget-container__content--empty-status" *ngIf="certificates.all === 0">
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetEmployeeCertificates.IsEmptyText' | translate}}</div>
            <img style="margin-right: 10px;" src="/assets/images/dashboard/clock.svg" alt="filter" class="mr-3">
        </div>
    </div>
</div>

