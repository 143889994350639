import {Component, OnInit, Input, OnChanges, SimpleChanges, HostListener} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';

@Component({
  selector: 'waiting-for-realization',
  templateUrl: './waiting-for-realization.component.html',
  styleUrls: ['./waiting-for-realization.component.scss']
})

export class WaitingForRealizationComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.prepareWidgetData();
  }

  widget: any;
  waitingForRealization: any;
  waiting = true;
  whiteBackground: boolean = false;
  isMobile: boolean;
  innerWidth = window.innerWidth;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private deviceService: DeviceDetectorService,
              private _router: Router,) {
    this.isMobile = deviceService.isMobile();
  }
  ngOnInit() {

    if(!this.preview){
      this.prepareWaitingForRealizationData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile || this.innerWidth < 1355 ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
      this.whiteBackground = this.widget.backgroundColor.toLowerCase() === 'ffffff';
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){
    this.waitingForRealization = {
      preboarding: 18,
      preboardingVisible: false,
      onboarding: 37,
      onboardingVisible: true,
      training: 60,
      trainingVisible: true,
      gamification: 23,
      gamificationVisible: false,
      path: 22,
      pathVisible: true,
      pool: 42,
      poolVisible: false,
      all: 160
    };
  }

  prepareWaitingForRealizationData(){
    this._api.Dashboard.getWaitingForRealizationData().subscribe((res) => {
      this.waitingForRealization = {
        preboarding: res.preboarding,
        preboardingVisible: res.preboarding > 0,
        onboarding: res.onboarding,
        onboardingVisible: res.onboarding > 0,
        training: res.training,
        trainingVisible: res.training > 0,
        gamification: res.gamification,
        gamificationVisible: res.gamification > 0,
        path: res.path,
        pathVisible: res.path > 0,
        pool: res.pool,
        poolVisible: res.pool > 0,
        all: res.all
      };
        this.waiting = false;
        this.prepareWidgetData()
    });
  }

  goTo(type){
    if(!this.preview){
      switch(type){
        case 'training':
          this._router.navigateByUrl('/app/myprogress/trainings');
          break;
        case 'path':
          this._router.navigateByUrl('/app/myprogress/paths');
          break;
        case 'pool':
          this._router.navigateByUrl('/app/myprogress/tests');
          break;
        case 'onboarding':
          this._router.navigateByUrl('/app/onboarding/my-onboarding');
          break;
        case 'preboarding':
          this._router.navigateByUrl('/app/preboarding/my-preboarding');
          break;
        case 'gamification':
          this._router.navigateByUrl('/app/preboarding/my-preboarding');
          break;
        default:
          return false;
      }
    }
  }
}