import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'new-paths-trainings',
  templateUrl: './new-paths-trainings.component.html',
  styleUrls: ['./new-paths-trainings.component.scss']
})

export class NewPathsTrainingsComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;
  public filesPath = environment.filesPath;

  widget: any;
  certificatesCount: number = 0;
  data: any;
  waiting = true;
  newPathsTrainingsObj: any[] = [];
  isMobile: boolean;

  pagination = {
    pageNumber: 1,
    pageSize: -159,
    totalElementsCount: 0,
    totalPages: 0
  };

  customCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    dotsEach: 3,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 3000,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        mergeFit: true,
        margin: 0,
        dotsEach: 3,
      },
    },
    nav: true
  }

  newPathsTrainingsCarousel: OwlOptions = {
    ...this.customCarouselOptions,
    navText: [`<div><img src='assets/icons/left-arrow.svg' alt='arrow-left'></div>`,
      `<div><img src='assets/icons/right-arrow.svg' alt='arrow-right'></div>`],
  }

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _translate: TranslateService,
              private _router: Router) {}

  ngOnInit() {
    if(!this.preview){
      this.prepareNewPathsTrainingsData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  prepareNewPathsTrainingsData(){
    this._api.Dashboard.getnewPathsTrainingsData().subscribe((res) => {
      this.newPathsTrainingsObj = [];
      this.newPathsTrainingsObj = this.newPathsTrainingsObj.concat(res);

      this.newPathsTrainingsObj.forEach(obj => {
        obj.image = this.photoUrl(obj);
      });

      this.newPathsTrainingsObj.sort(function(a,b){
        // @ts-ignore
        return new Date(a.publicationDate) - new Date(b.publicationDate);
      });

      this.waiting = false;
      this.prepareWidgetData()
    });
  }

  getExampleData(){
    this.newPathsTrainingsObj = [
      {
        id: 0,
        name: this._translate.instant('DashboardWidgetNewPathsTraining.Example'),
        type: 'path',
        image: '/assets/images/dashboard/new-paths-trainings-example.png'
      }
    ];
  }

  photoUrl(data) {
    if (data.photoUrl) {
      return this.filesPath + data.photoUrl + '?token=' + this._helper.hashUserToken();
    } else {
      if (data.idPath) {
        return 'assets/images/default/path-background.jpg'
      } else {
        return 'assets/images/default/training-background.jpg'
      }
    }
  }

  goTo(data){
    if(!this.preview) {
      if (data.idPath) {
        this._router.navigateByUrl('/app/path/path-view/' + data.idPath);
      } else {
        this._router.navigateByUrl('/app/training/player/' + data.idTraining);
      }
    }
  }
}