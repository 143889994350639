<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-recently-created' + ' widget-container-size-' + (setSize ? widget.sizeClass : '')  + (preview ? ' no-overflow-table' : ''))"
     [style.background]="'#' + widget.backgroundColor"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.RecentlyCreated' | translate}}</div>

    <div class="widget-container__content">
        <div class="widget-container__content--employee-logs" *ngIf="tableItems.length > 0">
            <app-data-grid *ngIf="tableItems.length > 0"
                            [columns]="columns"
                            [items]="tableItems"
                            [request]="request"
                            (loadData)="getData($event)"
                            [noTabs]="false"
                            [selection]="false"
                            [hideHead]="true">
            </app-data-grid>
        </div>
        <div class="widget-container__content--empty-status" *ngIf="tableItems.length === 0">
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetRecentlyCreated.IsEmptyText1' | translate}}</div>
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetRecentlyCreated.IsEmptyText2' | translate}}</div>
            <img style="margin-right: 10px;" src="/assets/images/dashboard/clock.svg" alt="filter" class="mr-3">
        </div>
    </div>
</div>

