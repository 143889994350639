<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-employee-logs' + ' widget-container-size-' + (setSize ? widget.sizeClass : '') + (preview ? ' no-overflow-table' : ''))"
     [style.background]="'#' + widget.backgroundColor"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.EmployeeLogs' | translate}}</div>

    <svg *ngIf="isMobile" class="background-icon"  width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#0E66C9" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" d="M30 46.6666C27.7131 46.6666 25.5557 46.2291 23.528 45.3541C21.5002 44.4791 19.7316 43.287 18.2221 41.7778C16.713 40.2684 15.5209 38.4998 14.6459 36.472C13.7709 34.4442 13.3334 32.2869 13.3334 29.9999C13.3334 27.6944 13.7709 25.5277 14.6459 23.4999C15.5209 21.4721 16.713 19.7083 18.2221 18.2083C19.7316 16.7083 21.5002 15.5208 23.528 14.6458C25.5557 13.7708 27.7131 13.3333 30 13.3333C32.3056 13.3333 34.4723 13.7708 36.5 14.6458C38.5278 15.5208 40.2917 16.7083 41.7917 18.2083C43.2917 19.7083 44.4792 21.4721 45.3542 23.4999C46.2292 25.5277 46.6667 27.6944 46.6667 29.9999C46.6667 32.2869 46.2292 34.4442 45.3542 36.472C44.4792 38.4998 43.2917 40.2684 41.7917 41.7778C40.2917 43.287 38.5278 44.4791 36.5 45.3541C34.4723 46.2291 32.3056 46.6666 30 46.6666ZM31 31.3887L29.028 33.3612C28.7593 33.6295 28.625 33.9535 28.625 34.3333C28.625 34.713 28.7593 35.037 29.028 35.3053C29.2963 35.5739 29.6203 35.7083 30 35.7083C30.3798 35.7083 30.7038 35.5739 30.9721 35.3053L35.3055 30.972C35.5832 30.6942 35.7221 30.3702 35.7221 29.9999C35.7221 29.6296 35.5832 29.3056 35.3055 29.0278L30.9721 24.6945C30.7038 24.4259 30.3798 24.2916 30 24.2916C29.6203 24.2916 29.2963 24.4259 29.028 24.6945C28.7593 24.9628 28.625 25.2869 28.625 25.6666C28.625 26.0463 28.7593 26.3703 29.028 26.6387L31 28.6112H25C24.602 28.6112 24.271 28.7431 24.0071 29.007C23.7432 29.2709 23.6113 29.6019 23.6113 29.9999C23.6113 30.398 23.7432 30.7289 24.0071 30.9928C24.271 31.2567 24.602 31.3887 25 31.3887H31Z" fill="#0E66C9"/>
    </svg>


    <div *ngIf="!isMobile" class="widget-container__content">
        <div class="widget-container__content--employee-logs">
            <app-data-grid *ngIf="tableItems.length > 0"
                            [columns]="columns"
                            [items]="tableItems"
                            [request]="request"
                            (loadData)="getData($event)"
                            [noTabs]="false"
                            [selection]="false"
                            [hideHead]="true">
            </app-data-grid>
        </div>
    </div>
</div>

