<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-favorite' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardCreator.Certificate' | translate}}</div>

    <svg class="background-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#FCA400" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" fill-rule="evenodd" clip-rule="evenodd" d="M17.7093 43.681C17.2601 43.681 16.8756 43.5211 16.5557 43.2012C16.2359 42.8813 16.0759 42.4968 16.0759 42.0477L16.5248 13.6685C16.5248 13.2193 16.6847 12.8348 17.0046 12.5149C17.3244 12.1951 17.7089 12.0352 18.1581 12.0352L41.2256 12.355C41.6747 12.355 42.0593 12.5149 42.3791 12.8348C42.699 13.1547 42.8589 13.5392 42.8589 13.9884L42.5784 31.721C44.2752 33.1607 45.352 35.3088 45.352 37.7083C45.352 40.2822 44.1131 42.5667 42.199 43.9981V50.8261L37.5032 48.8116L32.8074 50.8261V43.9984C32.7591 43.9622 32.7112 43.9256 32.6637 43.8884L17.7093 43.681ZM25.7815 17.7812C24.9531 17.7812 24.2815 18.4528 24.2815 19.2812C24.2815 20.1097 24.9531 20.7812 25.7815 20.7812H33.1527C33.9811 20.7812 34.6527 20.1097 34.6527 19.2812C34.6527 18.4528 33.9811 17.7812 33.1527 17.7812H25.7815ZM42.352 37.7083C42.352 40.3863 40.181 42.5573 37.503 42.5573C34.825 42.5573 32.6541 40.3863 32.6541 37.7083C32.6541 35.0303 34.825 32.8594 37.503 32.8594C40.181 32.8594 42.352 35.0303 42.352 37.7083ZM20.8726 25.7109C20.8726 24.8825 21.5441 24.2109 22.3726 24.2109H36.5621C37.3906 24.2109 38.0621 24.8825 38.0621 25.7109C38.0621 26.5394 37.3906 27.2109 36.5621 27.2109H22.3726C21.5441 27.2109 20.8726 26.5394 20.8726 25.7109Z" fill="#FCA400"/>
    </svg>

    <div class="widget-container__content">
        <div class="widget-container__content--certificates" *ngIf="widget.certificatesCount > 0">
            <div class="widget-container__content--certificates--count">{{ widget.certificatesCount }}</div>
        </div>
        <div class="widget-container__content--empty-status" *ngIf="widget.certificatesCount === 0">
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetCertificate.IsEmptyText1' | translate}}</div>
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetCertificate.IsEmptyText2' | translate}}</div>
        </div>
    </div>
</div>

