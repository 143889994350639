<div class="user-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{(idUser === null ? 'AdminUsers.FormAddHeader' : 'AdminUsers.FormEditHeader') | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="form" autocomplete="off" *ngIf="positions" class="mb-3">
            <mat-form-field class="full-width">
                <input formControlName="name" matInput placeholder="{{ 'AdminUsers.FormName' | translate }}"
                       appUpperCaseEveryLetter>
                <mat-error>{{ validation.name }}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <input formControlName="surname" matInput placeholder="{{ 'AdminUsers.FormSurname' | translate }}"
                       appUpperCaseEveryLetter>
                <mat-error>{{ validation.surname }}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <input formControlName="email" matInput appLowerCase
                       placeholder="{{ 'AdminUsers.FormEmail' | translate }}">
                <mat-error>{{ validation.email }}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-select formControlName="positionId" placeholder="{{ 'Common.Position' | translate }}">
                    <mat-form-field class="full-width pl-3 pr-3 pt-2">
                        <input matInput maxlength="50" (keyup)="onPositionSearch($event.target.value)"
                               (keydown)="$event.stopPropagation()"
                               #name
                               placeholder="{{'Common.Position' | translate}}">
                        <button mat-icon-button
                                matSuffix
                                aria-label="Clear"
                                *ngIf="name.value"
                                (click)="name.value=''; onPositionSearch(name.value)"
                                color="primary">
                            <i class="material-icons clear-button"
                               matTooltip="{{'Common.Clear' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                            >close</i>
                        </button>
                    </mat-form-field>
                    <mat-option [value]="'-1'">{{'Common.Lack' | translate}}</mat-option>
                    <mat-option *ngFor="let position of positions"
                                [ngStyle]="{'display': position.visible === 1 ? 'flex' : 'none'}"
                                value="{{position.id}}">{{position.name | translate}}</mat-option>
                </mat-select>
                <mat-error>{{ validation.positionId }}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="form.get('positionId').value === '0'">
                <input formControlName="positionName"
                       matInput
                       #positionName
                       maxlength="100"
                       placeholder="{{'Positions.AddNew' | translate}}">
                <mat-error
                        *ngIf="form.get('positionName').hasError('required')">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                <mat-error
                        *ngIf="form.get('positionName').hasError('positionExists')">{{ 'Positions.PositionIsAlreadyTaken' | translate }}</mat-error>
                <mat-hint align="end">{{positionName.value.length}} / 100</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-select class="full-width" formControlName="supervisorid"
                            placeholder="{{ 'Common.Supervisor' | translate }}">
                    <mat-form-field class="full-width pl-3 pr-3 pt-2">
                        <input matInput maxlength="50" (keyup)="onSuperiorSearch($event.target.value)"
                               (keydown.space)="$event.stopPropagation()"
                               #name
                               placeholder="{{'AdminUsers.SearchForASupervisor' | translate}}">
                        <button mat-icon-button
                                matSuffix
                                aria-label="Clear"
                                *ngIf="name.value"
                                (click)="name.value=''; onSuperiorSearch(name.value)"
                                color="primary">
                            <i class="material-icons clear-button"
                               matTooltip="{{'Common.Clear' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                            >close</i>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let superior of superiors"
                                value="{{superior.id}}">{{superior.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-select multiple formControlName="groups" placeholder="{{ 'Common.group' | translate }}">
                    <mat-form-field class="full-width pl-3 pr-3 pt-2">
                        <input matInput maxlength="50" (keyup)="onGroupSearch($event.target.value)"
                               (keydown)="$event.stopPropagation()"
                               #name
                               placeholder="{{'Common.Group' | translate}}">
                        <button mat-icon-button
                                matSuffix
                                aria-label="Clear"
                                *ngIf="name.value"
                                (click)="name.value=''; onGroupSearch(name.value)"
                                color="primary">
                            <i class="material-icons clear-button"
                               matTooltip="{{'Common.Clear' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                            >close</i>
                        </button>
                    </mat-form-field>
                    <ng-container  *ngFor="let group of groups">
                        <mat-option [ngStyle]="{'display': group.visible == 1 ? 'flex' : 'none'}" value="{{group.id}}">{{group.name | translate}}</mat-option>
                    </ng-container>

                </mat-select>
            </mat-form-field>


            <mat-slide-toggle class="mt-3" color="primary"
                              formControlName="expiryDateUnlimited">
                {{'Common.AccessWithoutADeadline' | translate}}
            </mat-slide-toggle>

            <mat-form-field class="w-100 mt-3" *ngIf="form.get('expiryDateUnlimited').value === false">
                <input matInput formControlName="expiryDate"
                       [matDatepicker]="expiry"
                       id="expiryField"
                       readonly="readonly"
                       placeholder="{{'Common.ActiveAccessTo' |  translate}}"
                       (click)="openDatePicker(expiry)">
                <mat-datepicker-toggle matSuffix [for]="expiry"></mat-datepicker-toggle>
                <mat-datepicker #expiry></mat-datepicker>
            </mat-form-field>

            <mat-slide-toggle *ngIf="idUser === null" class="mt-3" color="primary"
                              formControlName="createWithoutPasswordAndInvitation">{{'AdminUsers.FormCreateWithoutPasswordAndInvitation' | translate}}</mat-slide-toggle>
            <mat-slide-toggle class="mt-3" color="primary"
                              formControlName="sendInvitation">{{'AdminUsers.FormSendInvitation' | translate}}</mat-slide-toggle>

            <mat-slide-toggle class="mt-3" color="primary"
                              formControlName="setPassword">
                {{'AdminUsers.FormSetPassword' | translate}}
            </mat-slide-toggle>

            <mat-form-field class="w-100 mt-2" *ngIf="form.get('setPassword').value === true">
                <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput
                       placeholder="{{ 'AdminUsers.FormPassword' | translate }} {{idUser === null? '*' : ''}}">
                <mat-icon matSuffix style="color: #8E94A0;"
                          (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error>{{ validation.password }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle *ngIf="form.get('setPassword').value === true" class="mt-3" color="primary"
                              formControlName="changePasswordRequired">
                {{'AdminUsers.FormChangePasswordRequired' | translate}}
            </mat-slide-toggle>

            <div class="mat-form-field-subscript-wrapper mat-error options-error"
                 *ngIf="idUser === null && form.get('createWithoutPasswordAndInvitation').value === false && form.get('sendInvitation').value === false && form.get('setPassword').value === false">{{ validation.createAccount }}</div>

            <div class="flex-items param-title" *hasAccess="['ROLE_ADMIN'], perms [];">
                <img src="assets/images/default/icon_settings2.svg"
                     alt="icon_settings2"/>{{'AdminUsers.OptionalConfiguration' | translate}}
            </div>

            <mat-form-field class="full-width mt-3">
                <mat-select placeholder="{{ 'Common.UserLanguage' | translate}} *"
                            formControlName="interfaceLanguageId">
                    <mat-option [value]="language.id"
                                *ngFor="let language of languages">{{language.fullName | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mt-3" *hasAccess="[]; perms ['admin:users:manage']; subscriptions ['oauth_integration'];">
                <mat-select placeholder="{{ 'AdminUsers.AccountType' | translate}} *"
                            formControlName="type">
                    <mat-option [value]="type.id"
                                *ngFor="let type of accountTypes">{{type.name | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-slide-toggle *hasAccess="['ROLE_ADMIN'], perms [];" class="" color="primary"
                              formControlName="isAdmin">{{'AdminUsers.FormIsAdmin' | translate}}</mat-slide-toggle>

            <ng-container *ngFor="let customField of _helper.getUser().customFields">
                <mat-form-field *ngIf="customField.type === 'text'" class="full-width mt-3">
                    <input [formControlName]="'customField_' + customField.id" matInput placeholder="{{ customField.name + (customField.isRequired === true ? ' *' : '')}}" />
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('required')">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('valueExists')">{{ 'CustomFields.ValueIsAlreadyTaken2' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="customField.type === 'date'" class="w-100 mt-3">
                    <input matInput [formControlName]="'customField_' + customField.id"
                           [matDatepicker]="customFieldInput"
                           readonly="readonly"
                           placeholder="{{ customField.name + (customField.isRequired === true ? ' *' : '') }}"
                           (click)="openDatePicker(customFieldInput)">
                    <mat-datepicker-toggle matSuffix [for]="customFieldInput"></mat-datepicker-toggle>
                    <mat-datepicker #customFieldInput></mat-datepicker>
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('required')">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('valueExists')">{{ 'CustomFields.ValueIsAlreadyTaken2' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="customField.type === 'list'" class="full-width mt-3">
                    <mat-select [formControlName]="'customField_' + customField.id"
                                placeholder="{{ customField.name + (customField.isRequired === true ? ' *' : '') }}"
                                formControlName="type">
                        <mat-option [value]="0" *ngIf="customField.isRequired === false">{{ 'Common.Lack' | translate }}</mat-option>
                        <mat-option [value]="value.id" *ngFor="let value of customField.values">{{ value.value }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('required')">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                    <mat-error *ngIf="form.get('customField_' + customField.id).hasError('valueExists')">{{ 'CustomFields.ValueIsAlreadyTaken2' | translate }}</mat-error>
                </mat-form-field>
            </ng-container>
        </form>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button [disabled]="isLoading" (click)="save()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Save' | translate}}</button>
    </div>
</div>
